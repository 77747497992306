import React from "react"
import Layout from "../../components/layout"
import { Link, GatsbyLinkProps } from "gatsby"

const services = [
    { name: "Consulting Service (WIP)", path: "consulting-service" },
    { name: "Package Design", path: "package-design" },
    { name: "Co-Design (WIP)", path: "co-design" },
    { name: "PCB Design & Layout", path: "pcb-design-layout" },
    { name: "Signal Integrity", path: "signal-integrity" },
    { name: "Library Management (WIP)", path: "library-management" },
    { name: "Valor & DFM Services (WIP)", path: "valor-dfm-services" },
]

// markup
const ServicesPage = () => {
    return (
        <Layout>
            <div className="mx-auto container px-4">
                <h1>Services</h1>
                <hr className="mb-4" />
                <ul>
                    {services.map((service) => (
                        <li>
                            <Link to={service.path} key={service.name}>
                                {service.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default ServicesPage
